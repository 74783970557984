import * as React from "react"
import { PortableText } from '@portabletext/react'
import PropTypes from "prop-types"

const Locations = ({ offices }) => {

  return (
    <div className="locations container">
      <h2 className="descriptor">Locations</h2>
      <ul className="locations__locations">
        {offices.map(office => (
          <li key={office.node.id} className="location">
            <h6 className="location__city">{office.node.city}</h6>
            <div className='location__info'>
              <div className="location__address"><PortableText value={office.node._rawAddress} /></div>
              <p className="location__phone"><a href={`mailto: ${office.node.email}`} >{office.node.email}</a></p>
              <p className="location__phone"><a href={`tel:${office.node.phone.replace('+1 ', '').split(' ').join('-')}`}>{office.node.phone}</a></p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

Locations.propTypes = {
  offices: PropTypes.array.isRequired
}

export default Locations