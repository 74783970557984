import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AboutIntro from "../../components/aboutIntro";
import Contacts from "../../components/contacts"
import Locations from "../../components/locations";
import Socials from "../../components/socials";

const Contact = ({ location, data }) => {
  const contacts = data.sanityContact;
  const socials = data.sanitySocial.social;
  const offices = data.allSanityOffice.edges;

  return (
    <Layout
      scheme="dark"
      location={location}
      pageCategory="agency"
    >
      <Seo title="MullenLowe U.S. — Contact" />
      <AboutIntro copy={data.sanityContactPage._rawIntroCopy} />
      <Contacts contacts={contacts} />
      <Locations offices={offices} />
      <Socials socials={socials} />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityContactPage {
      _rawIntroCopy
    }
    sanityContact {
      contactNewBiz {
        email
        name
      }
      contactPress {
        email
        name
      }
    }
    sanitySocial {
      social {
        link
        name
        _key
      }
    }
    allSanityOffice(sort: {fields: city}) {
      edges {
        node {
          phone
          city
          _rawAddress
          id
          email
        }
      }
    }
  }
`

export default Contact