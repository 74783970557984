import * as React from 'react'
import PropTypes from 'prop-types'

const Socials = ({ socials }) => {
  return (
    <div className="socials container">
      <h2 className="descriptor">Socials</h2>
      <ul className="socials__socials">
        {socials.map(social => (
          <li key={social._key} className="socials__social">
            <a className="socials__social-link" href={social.link} target="_blank" rel="noopener noreferrer">{social.name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

Socials.propTypes = {
  socials: PropTypes.array.isRequired
}

export default Socials